import { Link, graphql } from "gatsby"
// import BackgroundImage from "gatsby-background-image"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import marked from "marked"
import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import loadable from "@loadable/component"
import pMinDelay from "p-min-delay"
import { LazyLoadComponent } from "react-lazy-load-image-component"
import styled from "styled-components"
import "./casestudy-template.css"
const remark = require("remark")
const externalLinks = require("remark-external-links")
const html = require("remark-html")

const CaseStudySwiper = loadable(() =>
  pMinDelay(import("../components/swiper/swiper"), 500)
)
// const slugify = require("slugify")

const BgImage = styled.div`
  position: relative;
  @media (min-width: 375px) {
    &::before {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      transition: opacity 0.5s ease 250ms;
      background-image: url(${props => props.background});
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      background-repeat-x: no-repeat;
      background-repeat-y: no-repeat;
    }
  }
`

const Banner = ({ data }) => {
  const CsBannerImg = getImage(data.bannerImage.asset.gatsbyImageData)
  const CsBannerBgIMg = convertToBgImage(CsBannerImg)

  return (
    <>
      <BgImage
        // Tag="div"
        background={CsBannerBgIMg?.fluid?.src}
        className="hero-banner"
        // Spread bgImage into BgImage:
        // {...CsBannerBgIMg}
        // preserveStackingContext
      >
        <div id="hero-case-studies">
          <div className="container casestudy-hero">
            <div className="row">
              <div className="col-md-10 mx-auto">
                <div className="casestudy-banner-content text-center title-banner bg-white">
                  <h1 className="casestudy-title font-weight-medium text-grey mt-2">
                    {data.title}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </BgImage>
    </>
  )
}

const AllCaseStudy = ({ data, pageContext, location }) => {
  const [mkd, setMkd] = useState()
  const [mkdSecond, setMkdSecond] = useState()
  const [mkd2, setMkd2] = useState()
  const [mkd2Solution, setMkd2Solution] = useState()
  const [mkd3, setMkd3] = useState()
  const cases = data.sanityCaseStudy
  const studies = data.allSanityCaseStudy.edges

  const image = getImage(cases?.clientImage?.asset?.gatsbyImageData)
  const bgImage = convertToBgImage(image)

  const imageSecond = getImage(cases?.clientImageSecond?.asset?.gatsbyImageData)
  const bgImageSecond = convertToBgImage(imageSecond)

  const ClientImg = getImage(cases?.solutionImage?.asset?.gatsbyImageData)
  const ClientBgImg = convertToBgImage(ClientImg)

  const ClientImgSecond = getImage(
    cases?.solutionImageSecond?.asset?.gatsbyImageData
  )
  const ClientBgImgSecond = convertToBgImage(ClientImgSecond)

  const ResultsImg = getImage(cases?.resultImage?.asset?.gatsbyImageData)
  const ResultsBgImg = convertToBgImage(ResultsImg)

  const { previous, next } = pageContext

  useEffect(() => {
    remark()
      .use(externalLinks)
      .use(html)
      .process(cases.clientRight, function(err, file) {
        if (err) throw err
        setMkd(file)
        // console.log("TT", String(file))
      })
    remark()
      .use(externalLinks)
      .use(html)
      .process(cases.clientRightSecond, function(err, file) {
        if (err) throw err
        setMkdSecond(file)
        // console.log("TT", String(file))
      })
    remark()
      .use(externalLinks)
      .use(html)
      .process(cases.solutionRight, function(err, file) {
        if (err) throw err
        setMkd2(file)
        // console.log("TT", String(file))
      })
    remark()
      .use(externalLinks)
      .use(html)
      .process(cases.solutionRightSecond, function(err, file) {
        if (err) throw err
        setMkd2Solution(file)
        // console.log("TT", String(file))
      })
    remark()
      .use(externalLinks)
      .use(html)
      .process(cases.resultLeft, function(err, file) {
        if (err) throw err
        setMkd3(file)
        // console.log("TT", String(file))
      })
  }, [])

  return (
    <Layout location={location} banner={<Banner data={cases} />}>
      <Seo title={cases.metaTitle} description={cases.metaDescription} />

      <div className="page-content pb-0 case-studies-inner bg-grey">
        <section id="client" className="mb-5 bg-grey">
          <div className="container ">
            <div className="rounded overflow-hidden">
              {cases.firstImage ? (
                <GatsbyImage
                  placeholder="blurred"
                  // image={node.image.asset.gatsbyImageData}
                  image={cases?.firstImage?.asset?.gatsbyImageData}
                  className="img-fluid"
                  alt={cases.title}
                  // width={600}
                />
              ) : (
                ""
              )}
              <div className="row mx-0">
                <div className="col-12 mb-4 px-0">
                  <div className="row mx-0">
                    <div className="col-md-10 mx-auto px-0">
                      <div
                        className="client-content"
                        dangerouslySetInnerHTML={{
                          __html: `<div class="text-grey">${marked(
                            String(mkd)
                          )}</div>`,
                        }}
                      />
                    </div>
                    {/* <div className="col-md-10 mx-auto px-0">
                      {cases.clientImage ? (
                        <BgImage
                          className="client-image"
                          // Spread bgImage into BgImage:
                          // {...bgImage}
                          background={bgImage?.fluid?.src}
                          // preserveStackingContext
                        >
                          <GatsbyImage
                            placeholder="blurred"
                            image={cases?.firstImage?.asset?.gatsbyImageData}
                            className="img-fluid d-block d-md-none"
                            alt="client"
                            // width={600}
                          />
                        </BgImage>
                      ) : (
                        ""
                      )}
                    </div> */}
                  </div>
                </div>
                <div className="col-12 px-0">
                  <div className="row mx-0">
                    {/* <div className="col-md-10 mx-auto px-0 p-">
                      {cases.clientRightSecond ? (
                        <div
                          className="client-content text-grey"
                          dangerouslySetInnerHTML={{
                            __html: marked(String(mkdSecond)),
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </div> */}
                    {/* <div className="col-md-10 mx-auto px-0">
                      {cases.clientImageSecond ? (
                        <BgImage
                          className="client-image"
                          // Spread bgImage into BgImage:
                          // {...bgImage}
                          background={bgImageSecond?.fluid?.src}
                          // preserveStackingContext
                        >
                          <GatsbyImage
                            placeholder="blurred"
                            image={cases?.firstImage?.asset?.gatsbyImageData}
                            className="img-fluid d-block d-md-none"
                            alt="client"
                            // width={600}
                          />
                        </BgImage>
                      ) : (
                        ""
                      )}
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="solution" className="mb-5 bg-white">
          <div className="divider-paper2" />
          <div className="container ">
            <div className="rounded overflow-hidden">
              <div className="row mx-0">
                <div className="col-12 px-0 mb-4">
                  <div className="row mx-0">
                    <div className="col-md-10 mx-auto px-0">
                      <div
                        className="client-content text-grey"
                        dangerouslySetInnerHTML={{
                          __html: marked(String(mkd2)),
                        }}
                      />
                    </div>
                    {/* <div className="col-md-10 mx-auto px-0">
                      {cases.solutionImage ? (
                        <BgImage
                          // Tag="div"
                          className="client-image"
                          // Spread bgImage into BgImage:
                          background={ClientBgImg?.fluid?.src}
                          // preserveStackingContext
                        >
                          <GatsbyImage
                            placeholder="blurred"
                            image={cases?.secondImage?.asset?.gatsbyImageData}
                            className="img-fluid d-block d-md-none"
                            alt="client"
                            // width={600}
                          />
                        </BgImage>
                      ) : (
                        ""
                      )}
                    </div> */}
                  </div>
                </div>
                <div className="col-12 px-0 mb-4">
                  <div className="row mx-0">
                    <div className="col-md-10 mx-auto px-0">
                      {cases.solutionRightSecond ? (
                        <div
                          className="client-content text-grey"
                          dangerouslySetInnerHTML={{
                            __html: marked(String(mkd2Solution)),
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    {/* <div className="col-md-10 mx-auto px-0">
                      {cases.solutionImageSecond ? (
                        <BgImage
                          // Tag="div"
                          className="client-image"
                          // Spread bgImage into BgImage:
                          background={ClientBgImgSecond?.fluid?.src}
                          // preserveStackingContext
                        >
                          <GatsbyImage
                            placeholder="blurred"
                            image={cases?.secondImage?.asset?.gatsbyImageData}
                            className="img-fluid d-block d-md-none"
                            alt="client"
                            // width={600}
                          />
                        </BgImage>
                      ) : (
                        ""
                      )}
                    </div> */}
                  </div>
                </div>
              </div>
              {cases.secondImage ? (
                <GatsbyImage
                  placeholder="blurred"
                  image={cases?.secondImage?.asset?.gatsbyImageData}
                  className="img-fluid"
                  alt="2"
                />
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="divider-paper" />
        </section>

        <section id="client" className="mb-5 bg-grey">
          <div className="container ">
            <div className="rounded overflow-hidden">
              {cases.ThirdImage ? (
                <GatsbyImage
                  placeholder="blurred"
                  image={cases?.ThirdImage?.asset?.gatsbyImageData}
                  ClassName="img-fluid"
                  alt="1"
                />
              ) : (
                ""
              )}
              <div className="row mx-0">
                <div className="col-md-10 mx-auto px-0">
                  <div
                    className="client-content text-grey"
                    dangerouslySetInnerHTML={{
                      __html: marked(String(mkd3)),
                    }}
                  />
                </div>
                {/* <div className="col-md-10 mx-auto px-0">
                  {cases.resultImage ? (
                    <BgImage
                      // Tag="div"
                      className="client-image"
                      // Spread bgImage into BgImage:
                      // {...ResultsBgImg}
                      // preserveStackingContext
                      background={ResultsBgImg?.fluid?.src}
                    >
                      <GatsbyImage
                        placeholder="blurred"
                        image={cases?.resultImage?.asset?.gatsbyImageData}
                        className="img-fluid d-block d-md-none"
                        alt="client"
                      />
                    </BgImage>
                  ) : (
                    ""
                  )}
                </div> */}
              </div>
            </div>
          </div>
        </section>

        {/* <section id="option-fourth-image" className="mb-5">
          <div className="container">
            <div className="overflow-hidden">
              {cases.OptionFourthImage ? (
                <GatsbyImage
                  placeholder="blurred"
                  image={cases?.OptionFourthImage?.asset?.gatsbyImageData}
                  className="img-fluid"
                  alt="4"
                />
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="container">
            <div className="overflow-hidden">
              {cases.OptionFifthImage ? (
                <GatsbyImage
                  placeholder="blurred"
                  image={cases?.OptionFifthImage?.asset?.gatsbyImageData}
                  className="img-fluid"
                  alt="5"
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </section> */}

        <div className="blog-nav mt-5 pt- bg-white">
          <div className="container">
            <div className="row">
              <div className="col-md-6 text-md-left">
                {previous ? (
                  <div className="prev-flex">
                    <div className="float-md-left mb-2">
                      <Link
                        to={`/case-study/${previous.slug.current}/`}
                        className="prev-icon"
                        aria-label={`Read about ${previous.title}`}
                      >
                        <i className="fa fa-angle-left" />
                      </Link>
                    </div>
                    <div className="text-md-left">
                      <span className="font-weight-bold text-uppercase text-muted d-block small">
                        Previous Post
                      </span>
                      <Link
                        aria-label="link"
                        to={`/case-study/${previous.slug.current}/`}
                      >
                        {previous.title}
                      </Link>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="col-md-6 text-md-right">
                {next ? (
                  <div className="next-flex">
                    <div className="text-md-right">
                      <span className="font-weight-bold text-uppercase text-muted d-block small">
                        Next Post
                      </span>
                      <Link
                        aria-label="link"
                        to={`/case-study/${next.slug.current}/`}
                      >
                        {next.title}
                      </Link>
                    </div>
                    <div className="float-md-right mb-2">
                      <Link
                        to={`/case-study/${next.slug.current}/`}
                        className="next-icon"
                        aria-label={`Read about ${next.title}`}
                      >
                        <i className="fa fa-angle-right" />
                      </Link>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>

        <section id="latest-projects" className="component bg-light">
          <div className="container">
            <div className="intro text-center mb-5">
              <h2 className="m-0">Our Latest Projects</h2>
            </div>

            <CaseStudySwiper
              slidesperview={3}
              responsive={{
                575: { slidesPerView: 1 },
                991: { slidesPerView: 2 },
              }}
            >
              {studies.map((stud, index) => {
                const StudyImg = getImage(
                  stud.node.mainImage !== null
                    ? stud.node.mainImage.asset.gatsbyImageData
                    : "https://source.unsplash.com/collection/2203755"
                )
                const StudyBgImg = convertToBgImage(StudyImg)

                return (
                  <div key={index} className="swiper-slide">
                    <BgImage
                      // Tag="div"
                      className="client-image position-relative"
                      // Spread bgImage into BgImage:
                      // {...StudyBgImg}
                      // preserveStackingContext
                      background={StudyBgImg?.fluid?.src}
                      style={{ borderRadius: "5px", height: "370px" }}
                    >
                      <div className="c-stud">
                        <Link
                          aria-label="link"
                          to={`/case-study/${stud.node.slug.current}/`}
                        >
                          <ul className="list-unstyled">
                            {stud.node.categories.length !== 0
                              ? stud.node.categories.map(cat => (
                                  <li className="text-blue" key={cat.title}>
                                    {cat.title}
                                    <span>, </span>
                                  </li>
                                ))
                              : null}
                          </ul>
                          <p className="h4 text-white font-weight-bold">
                            {stud.node.title}
                          </p>
                          <div className="std-cat">
                            <ul className="list-unstyled">
                              {stud.node.tag.length !== 0
                                ? stud.node.tag.map(tg => (
                                    <li className="text-grey" key={tg.title}>
                                      {tg.title}
                                      <span>, </span>
                                    </li>
                                  ))
                                : null}
                            </ul>
                          </div>
                        </Link>
                      </div>
                    </BgImage>
                  </div>
                )
              })}
            </CaseStudySwiper>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default AllCaseStudy
export const blogQuery = graphql`
  query CategoryTemplate($id: String!) {
    sanityCaseStudy(id: { eq: $id }) {
      id
      title
      metaTitle
      metaDescription
      discription
      slug {
        current
      }
      bannerImage {
        asset {
          gatsbyImageData(
            fit: FILL
            placeholder: BLURRED
            sizes: ""
            layout: CONSTRAINED
          )
        }
      }
      clientRight
      solutionRight
      solutionRightSecond
      resultLeft
      categories {
        id
        title
      }
      tag {
        id
        title
      }
    }
    allSanityCaseStudy {
      edges {
        node {
          id
          title
          categories {
            title
          }
          tag {
            title
          }
          mainImage {
            asset {
              gatsbyImageData(
                fit: FILL
                placeholder: BLURRED
                sizes: ""
                layout: CONSTRAINED
              )
            }
          }
          discription
          slug {
            current
          }
          author {
            id
            name
          }
        }
      }
      group(field: categories___title) {
        fieldValue
        edges {
          node {
            id
            title
            categories {
              title
            }
            tag {
              title
            }
            mainImage {
              asset {
                gatsbyImageData(
                  fit: FILL
                  placeholder: BLURRED
                  sizes: ""
                  layout: CONSTRAINED
                )
              }
            }
            slug {
              current
            }
            discription
          }
        }
      }
    }
  }
`
